import request from "../utils/request";

export function get_store_promotion_list_admin(data: JSON){
    return request({
        url: "/getStorePromotionListAdmin",
        method: "post",
        data: data
    });
}

export function get_store_promotion_detail_admin(data: JSON){
    return request({
        url: "/getStorePromotionDetailAdmin",
        method: "post",
        data: data
    });
}

export function delete_store_promotion_admin(data: JSON) {
    return request({
        url: "/deleteStorePromotionAdmin",
        method: "post",
        data: data
    });
}

export function save_store_promotion_detail_admin(data: JSON){
    return request({
        url: "/saveStorePromotionDetailAdmin",
        method: "post",
        data: data
    });
}