<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">店家管理</li>
            <li class="breadcrumb-item active" aria-current="page">店家資料管理</li>
            <li class="breadcrumb-item active" aria-current="page">店家優惠訊息</li>
            <li class="breadcrumb-item active" aria-current="page">店家優惠訊息編輯</li>
        </ol>
        </nav>
        <hr class="w-100">
        <Form @submit="save()" :validation-schema="schema" @invalid-submit="on_invalid_submit">
            <div class="col-12 row mx-auto data-form">
                <div class="mb-3">
                    <label for="store_code" class="form-label">店家編號</label>
                    <input type="text" class="form-control" id="store_code" v-model="promotion_data.store_code" :disabled="true">
                </div>
                <div class="mb-3">
                    <label for="tax_id" class="form-label">統一編號</label>
                    <input type="text" class="form-control" id="tax_id" v-model="promotion_data.tax_id" :disabled="true">
                </div>
                <div class="mb-3">
                    <label for="store_name" class="form-label">店家名稱</label>
                    <input type="text" class="form-control" id="store_name" v-model="promotion_data.store_name" :disabled="true">
                </div>
                <div class="mb-3">
                    <label for="promotion_content" class="form-label"><span class="required" v-if="$route.name !== 'StorePromotionManagementView'">*</span>優惠內容</label>
                    <Field name="promotion_content" v-slot="{ meta, field }" v-model="promotion_data.promotion_content" :disabled="$route.name === 'StorePromotionManagementView'">
                        <textarea
                            id="promotion_content"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            rows="8"
                            :disabled="$route.name === 'StorePromotionManagementView'"
                        />
                    </Field>
                    <ErrorMessage name="promotion_content" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label class="form-label"><span class="required" v-if="$route.name !== 'StorePromotionManagementView'">*</span>優惠訊息啟用狀態</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <Field name="status_options" v-slot="{ meta, field }" v-model="promotion_data.status">
                                <input
                                    id="status_radio_1"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="1"
                                    :checked="promotion_data.status === 1"
                                    :disabled="$route.name === 'StorePromotionManagementView'"
                                />
                            </Field>
                            <label class="form-check-label" for="status_radio_1">啟用</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <Field name="status_options" v-slot="{ meta, field }" v-model="promotion_data.status">
                                <input
                                    id="status_radio_2"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="0"
                                    :checked="promotion_data.status === 0"
                                    :disabled="$route.name === 'StorePromotionManagementView'"
                                />
                            </Field>
                            <label class="form-check-label" for="status_radio_2">停用</label>
                        </div>
                    </div>
                    <ErrorMessage name="status_options" class="radio-invalid-feedback"/>
                </div>
                <div class="mb-3 mx-auto text-center">
                    <button type="submit" class="btn m-2 btn-primary" :disabled="button_disabled" v-if="$route.name !== 'StorePromotionManagementView'">送出</button>
                    <button type="button" class="btn m-2 btn-danger" v-on:click="go_back()">返回</button>
                </div>
                <div class="mb-3">
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
import { 
    get_store_promotion_detail_admin,
    save_store_promotion_detail_admin,
} from '../../api/store_promotion_admin';
import { mapActions, mapGetters } from 'vuex';
import { get_store_basic_info_admin } from '../../api/store_admin';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { store_promotion_rules } from '../../rules/store/store_promotion';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    data() {
        return {
            promotion_data: {
                store_code: "",
                tax_id: "",
                store_name: "",
                promotion_content: "",
                status: null,
            },
            button_disabled: false,
            can_go_back: false,
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search() {
            get_store_promotion_detail_admin({store_id: this.$route.params.store_id, store_promotion_id: this.$route.params.id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout()
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'StorePromotionManagementList' });
                } else if (response.data.success === false && response.data.error_code === "1003") {
                    this.$router.push({ name: 'StorePromotionManagementList' });
                } else {
                    this.promotion_data = response.data.response_data;
                }
            })
        },
        get_store_info() {
            get_store_basic_info_admin({store_id: this.$route.params.store_id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout()
                } else if (response.data.success === false && response.data.error_code === "1003") {
                    this.$router.push({ name: 'StoreManagementList' });
                } else {
                    this.promotion_data.store_id = response.data.response_data.store_id;
                    this.promotion_data.store_code = response.data.response_data.store_code;
                    this.promotion_data.tax_id = response.data.response_data.tax_id;
                    this.promotion_data.store_name = response.data.response_data.store_name;
                }
            })
        },
        save() {
            this.button_disabled = true;
            save_store_promotion_detail_admin(this.promotion_data).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: response.data.message,
                        icon: 'success',
                        showCloseButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '確認',
                    }).then((result) => {
                        if (result.isConfirmed || result.dismiss === "close") {
                            this.$router.push({ name: 'StorePromotionManagementList' });
                        }
                    })
                }
            }, error => {
                this.button_disabled = false;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: error.response.data.message,
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            });
        },
        on_invalid_submit() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '錯誤訊息',
                text: '尚有欄位未填寫或是填寫錯誤！',
                icon: 'error',
                cancelButtonText: '關閉',
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: false,
            });
        },
        go_back() {
            if (this.can_go_back === true) {
                this.$router.back();
            } else {
                this.$router.push({name: "StorePromotionManagementList"});
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        const router = useRouter();
        if (router.options.history.state.back !== null) {
            this.can_go_back = true;
        }
        if (this.$route.params.id !== undefined) {
            this.search();
        } else {
            this.get_store_info()
        }
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
        schema() {
            return store_promotion_rules;
        },
    }
};
</script>
